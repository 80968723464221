.happy-customer-review-heading-txt {
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #231e1f;
}
.happy-customer-review-paragraph-txt {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #231e1f;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
  .happy-customer-review-paragraph-txt {
    font-size: 20px;
    margin-top: -10px;
  }
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
  .happy-customer-review-heading-txt {
    font-size: 26px;
    font-weight: 700;
  }
  .happy-customer-review-paragraph-txt {
    font-size: 19px;
    margin-top: -10px;
  }
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
  .happy-customer-review-heading-txt {
    font-size: 25px;
    font-weight: 700;
  }
  .happy-customer-review-paragraph-txt {
    font-size: 18px;
    font-weight: 500;
  }
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
  .happy-customer-review-heading-txt {
    font-size: 25px;
    font-weight: 700;
  }
  .happy-customer-review-paragraph-txt {
    font-size: 18px;
    font-weight: 500;
  }
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .happy-customer-review-heading-txt {
    font-size: 25px;
    font-weight: 700;
  }
  .happy-customer-review-paragraph-txt {
    font-size: 18px;
    font-weight: 500;
  }
}
