/* Background gradient */
.copy-right-logo-section {
  display: flex;
  padding: 10px 0;
  /* justify-content: flex-start; */
  justify-content: space-between;
}
.copy-right-container-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #5a5c60;
}
.designed-by-container-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(90, 92, 96, 1);
}
.designed-by-screen-root-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.2px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #000;
  margin-left: 4px;
}
.copy-right-vertical-line {
  border-left: 1px solid #000; /* Adjust color and width as needed */
  height: 15px; /* Adjust height as needed */
  margin: 0 10px; /* Adjust margin to control spacing around the line */
}
.label-btn-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #5a5c60;
  cursor: pointer;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .copy-right-container-txt {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
