.heading-txt {
  font-family: Manrope;
  font-size: 22px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: center;
}
.sub-heading-txt {
  font-family: Manrope;
  font-size: 17px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
  margin-top: 20px;
}
.navigate-btn {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: center;
  margin-top: 20px;
}

/* Button Styling */
.protect-btn {
  background-color: #1a73e8;
  color: white;
  margin-top: 20px;
  padding: 7px 20px;
  border: none;
  font-size: 1rem;
}
.gradient-button {
  background: radial-gradient(
    101.2% 347.49% at 7.18% -16.67%,
    #318fe8 0%,
    #2e77be 34.1%,
    #231e1f 100%
  );
  border: none; /* Remove default border */
  color: #fff; /* Text color */
  font-weight: bold; /* Optional: Bold text */
  padding: 10px 20px; /* Padding */
  transition: background 0.3s ease; /* Smooth transition for hover effects */
}
.gradient-button:hover {
  background: linear-gradient(
    45deg,
    #231e1f,
    #2e77be
  ); /* Reverse gradient on hover */
}
