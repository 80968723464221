.custom-dropdown-toggle {
  width: 100%;
  background-color: #f7f9fc;
  border-width: 1px;
  border-color: lightgrey;
  justify-content: left; /* Align text to the left */
  margin-top: 11px;
  border-radius: 6px;
  font-size: 14px;
  color: #6c757d;
  text-align: left; /* Ensure text is aligned to the left */
  position: relative; /* Necessary for positioning the caret */
  padding-right: 2.5rem; /* Space for the caret */
}

.custom-dropdown-toggle::after {
  position: absolute;
  right: 1rem; /* Position caret to the right */
  top: 50%;
  transform: translateY(-50%);
}

.terms-link {
  color: "#1e90ff";
  text-decoration: underline; /* Optional: Add underline to indicate it's a link */
}

.terms-link:hover {
  color: "#1e90ff"; /* Optional: Change color on hover */
  text-decoration: underline;
}

/* ****** New Code ****** */
.gradient-bg {
  background-image: linear-gradient(
    to right,
    rgba(0, 174, 255, 0),
    rgba(0, 174, 255, 0.3)
  );
  /* height: 100vh; */
  display: flex;
  justify-content: center;
}

.vertical-line {
  height: 15px; /* Line height */
  margin: auto;
  border-left: 2px solid #000; /* Line color and width */
}

/* *********************** PROTECT SCREEN FORM STYLING *********************** */
/* .back-arrow-img-title-view {
  width: 95%;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: red;
} */
.back-arrow-img-container {
  max-width: 40px;
}
.back-arrow-img {
  width: 25px;
}
.back-arrow-title-container {
  width: 93%;
}
.back-arrow-title-txt {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: sans-serif;
  margin-left: 10px;
  margin-top: 10px;
}

/* ****** Media Queries Styling ****** */
@media (max-width: 576px) {
  .back-arrow-title-txt {
    font-size: 15px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .back-arrow-title-txt {
    font-size: 17px;
  }
}

@media (min-width: 768px) {
  .back-arrow-title-txt {
    font-size: 18px;
  }
}
