Default background color for the accordion header .accordion-button {
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  border: 0px solid #fff; /* Default border color when collapsed */
}

/* Background color when accordion is open */
.accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #000;
  border: 0px solid #fff; /* Border color when open */
}

/* Hover effect for collapsed state */
.accordion-button.collapsed:hover {
  background-color: #fff;
  color: #000;
  border: 0px solid #fff; /* Change border color on hover when collapsed */
}

/* Additional styling for the accordion body */
.accordion-body {
  background-color: #f8f9fa;
  color: #333;
}

/* Add a bottom border to accordion items */
.accordion-item {
  border-bottom: 1px solid #ddd; /* Add a horizontal bar (border) */
}

/* Remove the border from the last accordion item */
.accordion-item:last-child {
  border-bottom: none;
}
.questions-heading-txt {
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #231e1f;
}
.accordian-question-heading-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #231e1f;
}
.accordian-answer-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #5a5c60;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
  .questions-heading-txt {
    font-size: 32px;
    font-weight: 600;
  }
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
  .questions-heading-txt {
    font-size: 28px;
    font-weight: 600;
  }
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
  .questions-heading-txt {
    font-size: 28px;
    font-weight: 600;
  }
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
  .questions-heading-txt {
    font-size: 27px;
    font-weight: 600;
  }
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .questions-heading-txt {
    font-size: 25px;
    font-weight: 600;
  }
}
