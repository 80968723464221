.form-guide-card {
  border-radius: 8px;
  width: 100%;
}
.form-fill-label-Txt-container {
  background: linear-gradient(
    to left,
    rgba(0, 75, 140, 0.1),
    rgba(0, 174, 239, 0.15)
  );
  width: 100%;
  padding: 37px;
  border-radius: 8px;
  padding-bottom: 20px;
}
.form-fill-label-Txt {
  /* font-family: sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center; */
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  letter-spacing: 0.02em;
  text-align: center;
}

/* Card Fill Container */
.guide-fill-container {
  justify-content: center;
  display: flex;
  margin-top: 10px;
}
.guide-fill-images {
  width: 48px;
  height: 48px;
  gap: 0px;
  /* opacity: 0px; */
}
.guide-fill-heading-Txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #231e1f;
}
.guide-fill-sub-heading-Txt {
  color: #555555;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0.01em;
  text-align: center;
}

/* Screenshot Error Warning */
.screenshot-not-accept-warning-container {
  width: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.warning-card-container {
  width: 280px;
  height: 34px;
  display: flex;
  margin-top: 20px;
  border-radius: 20px;
  align-items: center;
  background: #ffe2c0c4;
  justify-content: center;
}
.warning-icon {
  width: 16px;
  height: 16px;
  gap: 0px;
  /* opacity: 0px; */
}
.screenshot-not-accept-label-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-left: 10px;
  color: #231e1f;
}

/* Plan Details */
.plan-details-card {
  padding: 10px;
  background: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
}
.plan-detail-heading-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
}
.plan-detail-label-key-txt {
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  color: #666666;
  padding: 2px;
}
.plan-detail-label-value-txt {
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  text-align: left;
}
.text-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
}
.text-content .highlight {
  color: #f89c35; /* Orange highlight for DMI Screen Protect */
}

/* •••••••••••••••••••••• BREAK POINT TEST CODE •••••••••••••••••••••• */
/* Extra Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
  .form-fill-label-Txt-container {
    padding: 35px;
  }
  .guide-fill-container {
    margin-top: 20px;
  }
  .form-fill-label-Txt {
    font-size: 15px;
    text-align: left;
  }
  .guide-fill-images {
    width: 40px;
    height: 40px;
  }
  .guide-fill-heading-Txt {
    font-size: 15px;
    text-align: left;
    margin-left: 10px;
  }
  .guide-fill-sub-heading-Txt {
    font-size: 13px;
    text-align: left;
    margin-left: 10px;
  }
  .screenshot-not-accept-warning-container {
    /* width: 65%; */
    width: 100%;
  }
  .warning-card-container {
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .screenshot-not-accept-label-txt {
    font-size: 13px;
    line-height: 18.2px;
  }
  .plan-details-card {
    margin-top: 20px;
  }
  .plan-detail-label-key-txt {
    width: 100%;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.12px;
    text-align: left;
    color: #666666;
  }
  .plan-detail-label-value-txt {
    width: 100%;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: left;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .form-fill-label-Txt-container {
    padding: 15px;
  }
  .guide-fill-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .form-fill-label-Txt {
    font-size: 15px;
    text-align: left;
  }
  .guide-fill-images {
    width: 40px;
    height: 40px;
  }
  .guide-fill-heading-Txt {
    font-size: 15px;
    text-align: left;
    margin-left: 10px;
  }
  .guide-fill-sub-heading-Txt {
    font-size: 13px;
    text-align: left;
    margin-left: 10px;
  }
  .plan-details-card {
    margin-top: 20px;
  }
  /* .form-fill-label-Txt {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 23.4px;
    letter-spacing: 0.02em;
    text-align: center;
  } */
  .screenshot-not-accept-warning-container {
    /* width: 50%; */
    width: 100%;
  }
  .warning-card-container {
    /* width: 70%; */
  }
  .plan-detail-label-key-txt {
    font-size: 13px;
  }
  .plan-detail-label-value-txt {
    font-size: 13px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .form-fill-label-Txt-container {
    padding: 15px;
  }
  .plan-details-card {
    padding: 10px;
    background: #fff;
    padding-left: 24px;
    padding-right: 24px;
  }
  .screenshot-not-accept-warning-container {
    /* width: 40%; */
    width: 100%;
  }
  .guide-fill-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .form-fill-label-Txt-container {
    padding: 20px;
  }
  .guide-fill-images {
    width: 40px;
    height: 40px;
  }
  .guide-fill-heading-Txt {
    font-size: 15px;
    text-align: center;
  }
  .guide-fill-sub-heading-Txt {
    font-size: 13px;
    text-align: center;
  }
  .screenshot-not-accept-warning-container {
    width: 100%;
  }
  .plan-details-card {
    padding: 5px;
    background: red;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: -5px;
  }
  .plan-detail-label-key-txt {
    font-size: 13px;
  }
  .plan-detail-label-value-txt {
    font-size: 13px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .form-fill-label-Txt-container {
    padding: 28px;
    margin-top: 20px;
  }
  .screenshot-not-accept-warning-container {
    width: 100%;
  }
  .plan-details-card {
    margin-top: -10px;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .form-fill-label-Txt-container {
    padding: 30px;
    margin-top: 10px;
  }
  .form-fill-label-Txt {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  .guide-fill-images {
    width: 40px;
    height: 40px;
  }
  .guide-fill-heading-Txt {
    font-size: 15px;
    text-align: center;
  }
  .guide-fill-sub-heading-Txt {
    font-size: 13px;
    text-align: center;
  }
  .screenshot-not-accept-warning-container {
    /* width: 80%; */
  }
  .screenshot-not-accept-label-txt {
    font-size: 13px;
  }
}
