.review-card-container {
  background: radial-gradient(
    99.23% 105.44% at 62.52% 105.44%,
    rgba(9, 78, 255, 0.1) 0%,
    rgba(109, 206, 255, 0.1) 100%
  );
  border: 1px solid lightgrey;
  border-radius: 6px;
}
.happy-customer-review-heading-txt {
  color: #000;
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 50px;
}
.user-name-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: left;
  color: #231e1f;
}
.user-description-txt {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #5a5c60;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
  .happy-customer-review-heading-txt {
    font-size: 32px;
    font-weight: 700;
  }
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .user-name-txt {
    font-size: 16px;
    font-weight: 700;
  }
  .happy-customer-review-heading-txt {
    font-size: 25px;
    font-weight: 700;
  }
}
