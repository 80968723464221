/* Background gradient */
.header-section {
  display: flex;
  padding: 10px 0;
  justify-content: flex-start;
}

/* Vertical Line */
.vertical-line {
  height: 15;
  margin: auto;
  border-left: 1.5px solid lightgrey;
}
