.bottom-modal .modal-dialog {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
}
.bottom-modal .modal-content {
  border-radius: 10px;
  padding-bottom: 0;
}
.pay-btn {
  width: 100px;
}

/* Plan Card */
.plan-details-card {
  padding: 10px;
  background: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
}
.plan-detail-heading-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
}
.plan-detail-label-key-txt {
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  color: #666666;
  padding: 2px;
}
.plan-detail-label-value-txt {
  width: 100%;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  text-align: left;
}
