/* Background gradient */
.screen-protect-section {
  background: linear-gradient(
    to left,
    rgba(0, 75, 140, 0.1),
    rgba(0, 174, 239, 0.15)
  );
  padding: 50px;
  /* padding: 10px; */
  padding-bottom: 90px;
  border-radius: 8px;
}

/* Text Content Styling */
.text-content h1 {
  font-size: 1.5rem;
  font-weight: bold;
}
.text-content .highlight {
  color: #f89c35; /* Orange highlight for DMI Screen Protect */
}

/* Button Styling */
.protect-btn {
  background-color: #1a73e8;
  color: white;
  margin-top: 20px;
  padding: 7px 20px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
}
.gradient-button {
  background: radial-gradient(
    101.2% 347.49% at 7.18% -16.67%,
    #318fe8 0%,
    #2e77be 34.1%,
    #231e1f 100%
  );
  border: none; /* Remove default border */
  color: #fff; /* Text color */
  font-weight: bold; /* Optional: Bold text */
  padding: 10px 20px; /* Padding */
  transition: background 0.3s ease; /* Smooth transition for hover effects */
}
.gradient-button:hover {
  background: linear-gradient(
    45deg,
    #231e1f,
    #2e77be
  ); /* Reverse gradient on hover */
}

/* Image Content */
.image-content {
  position: relative;
  text-align: center;
}
.phone-image {
  /* max-width: 80%; */
  width: 100%;
}

/* Feature Badges */
.features-row {
  margin-top: 40px;
}
.feature-badge {
  background-color: #e0f7fa;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
  font-size: 1.1rem;
  color: #333;
}

/*  */
.safeguard-heading-Txt {
  /* color: #000;
  font-size: 10px;
  font-weight: 700; */
  font-family: Manrope;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.4px;
  text-align: left;
  color: #000;
}
.dmi-screen-protect-heading-txt {
  /* font-size: 1.5rem;
  color: rgba(47, 128, 237, 1);
  font-weight: 700;
  margin-top: -10px; */
  font-family: Manrope;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 50.4px;
  text-align: left;
  margin-top: -25px;
  color: rgba(47, 128, 237, 1);
}

.paragraph-txt {
  /* font-size: 1rem;
  margin-top: -10px; */
  color: rgba(35, 30, 31, 1);
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: -10px;
}
.paragraph-txt-two {
  /* font-size: 24px; */
  font-size: 1rem;
  margin-top: -13px;
  color: rgba(35, 30, 31, 1);
}

/* Bottom Card */
.bottom-card-section {
  background: linear-gradient(
    to bottom,
    rgba(182, 227, 243, 255),
    rgba(202, 239, 250, 255)
  );
  width: 75%;
  bottom: 45px;
  position: relative;
  border-radius: 10px;
  padding: 15px 30px 15px 30px;
}
.green-tick-icon {
  max-width: 100%;
  height: auto;
}
.label-txt {
  /* color: #000;
  font-size: 13px;
  margin-top: 5px; */
  font-family: Manrope;
  font-size: 13px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: center;
}

/* ****** Media Queries ****** */
/* @media (max-width: 576px) {
  .screen-protect-section {
    background: linear-gradient(
      to left,
      rgba(0, 75, 140, 0.1),
      rgba(0, 174, 239, 0.15)
    );
    padding: 20px;
    padding-bottom: 90px;
    border-radius: 8px;
  }
  .label-txt {
    color: #000;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
  }
} */

/* @media (min-width: 576px) and (max-width: 768px) {
  .screen-protect-section {
    background: linear-gradient(
      to left,
      rgba(0, 75, 140, 0.1),
      rgba(0, 174, 239, 0.15)
    );
    padding: 20px;
    padding-bottom: 90px;
    border-radius: 8px;
  }
  .phone-image {
    width: 65%;
  }
  .green-tick-icon {
    width: 12%;
  }
  .label-txt {
    color: #000;
    font-size: 15px;
    margin-top: 5px;
  }
} */

/* @media (min-width: 768px) {
  .bottom-card-section {
    bottom: 45px;
    position: relative;
  }
  .phone-image {
    width: 80%;
  }
  .green-tick-icon {
    width: 16%;
  }
  .label-txt {
    color: #000;
    font-size: 17px;
    font-weight: bold;
    margin-top: 5px;
  }
} */
/* ••••••••••••••••••••••••••••••••••••••••••••••••••• */

/* ****** Media Queries for Responsive ****** */
/* @media (max-width: 576px) {
  .safeguard-heading-Txt {
    font-size: 25px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.4rem;
    margin-top: -37px;
    color: orange;
    text-shadow: "2px 2px 4px #f89c35";
  }
  .paragraph-txt {
    font-size: 16px;
    margin-top: -22px;
  }
  .paragraph-txt-two {
    font-size: 1rem;
    margin-top: -20px;
  }
  .btn-parent-container {
    margin-top: -5px;
  }
  .protect-btn {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .phone-image {
    width: 90%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 30%;
    bottom: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
} */

/*@media (min-width: 576px) and (max-width: 768px) {
  .safeguard-heading-Txt {
    font-size: 25px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.5rem;
    margin-top: -37px;
    color: orange;
    text-shadow: "2px 2px 4px #f89c35";
  }
  .paragraph-txt {
    font-size: 16px;
    margin-top: -22px;
  }
  .paragraph-txt-two {
    font-size: 1rem;
    margin-top: -20px;
  }
  .btn-parent-container {
  }
  .protect-btn {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .phone-image {
    width: 60%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}

@media (min-width: 992px) {
  .safeguard-heading-Txt {
    font-size: 25px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.5rem;
    margin-top: -37px;
    color: orange;
    text-shadow: "2px 2px 4px #f89c35";
  }
  .paragraph-txt {
    font-size: 16px;
    margin-top: -22px;
  }
  .paragraph-txt-two {
    font-size: 1rem;
    margin-top: -20px;
  }
  .btn-parent-container {
    /* margin-top: -5px; 
  }
  .protect-btn {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .phone-image {
    width: 60%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
} */

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
  .screen-protect-section {
    padding: 30px;
    border-radius: 8px;
    padding-bottom: 90px;
  }
  .safeguard-heading-Txt {
    font-size: 31px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.69rem;
    margin-top: -30px;
  }
  .paragraph-txt {
    font-size: 18.5px;
    margin-top: 0px;
  }
  .paragraph-txt-two {
    font-size: 18.5px;
    margin-top: -15px;
  }
  .btn-parent-container {
    margin-top: 0px;
  }
  .protect-btn {
    margin-top: 30px;
  }
  .phone-image {
    width: 100%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .green-tick-icon {
    width: 18%;
    height: auto;
    margin-bottom: 10px;
  }
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
  .screen-protect-section {
    padding: 30px;
    border-radius: 8px;
    padding-bottom: 90px;
  }
  .safeguard-heading-Txt {
    font-size: 31px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.69rem;
    margin-top: -30px;
  }
  .paragraph-txt {
    font-size: 18.5px;
    margin-top: 0px;
  }
  .paragraph-txt-two {
    font-size: 18.5px;
    margin-top: -15px;
  }
  .btn-parent-container {
    margin-top: 0px;
  }
  .protect-btn {
    margin-top: 30px;
  }
  .phone-image {
    width: 100%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 70px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  .green-tick-icon {
    width: 18%;
    height: auto;
    margin-bottom: 10px;
  }
}

/* For Medium Screens (tablets) */
@media (min-width: 768px) and (max-width: 992px) {
  .screen-protect-section {
    padding: 20px;
    border-radius: 8px;
    padding-bottom: 40px;
  }
  .safeguard-heading-Txt {
    font-size: 29px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.65rem;
    margin-top: -35px;
  }
  .paragraph-txt {
    font-size: 18.5px;
    margin-top: -15px;
  }
  .paragraph-txt-two {
    /* font-size: 1rem; */
    font-size: 18.5px;
    margin-top: -20px;
  }
  .btn-parent-container {
    margin-top: 0px;
  }
  .protect-btn {
    margin-top: 10px;
  }
  .phone-image {
    width: 60%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 35px;
  }
  .green-tick-icon {
    width: 18%;
    height: auto;
    margin-bottom: 10px;
  }
  .responsive-height {
    height: 630px;
  }
}

/* For Small Screens (mobiles) */
@media (max-width: 767px) {
  .screen-protect-section {
    padding: 20px;
    border-radius: 8px;
    padding-bottom: 90px;
  }
  .safeguard-heading-Txt {
    font-size: 28px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.6rem;
    margin-top: -30px;
  }
  .paragraph-txt {
    font-size: 18px;
    margin-top: -15px;
  }
  .paragraph-txt-two {
    font-size: 1rem;
    margin-top: -20px;
  }
  .btn-parent-container {
    margin-top: 0px;
  }
  .protect-btn {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .phone-image {
    width: 60%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 100%;
    bottom: 140px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .green-tick-icon {
    width: 16%;
    height: auto;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .responsive-height {
    height: 640px;
  }
}

/* For Extra Small Screens (mobiles smaller than 576px) */
@media (max-width: 576px) {
  .screen-protect-section {
    padding: 16px;
    border-radius: 8px;
    padding-bottom: 75px;
  }
  .safeguard-heading-Txt {
    font-size: 25px;
  }
  .dmi-screen-protect-heading-txt {
    font-size: 1.4rem;
    margin-top: -37px;
    color: orange;
    text-shadow: "2px 2px 4px #f89c35";
  }
  .paragraph-txt {
    font-size: 16px;
    margin-top: -22px;
  }
  .paragraph-txt-two {
    font-size: 1rem;
    margin-top: -20px;
  }
  .btn-parent-container {
    margin-top: -5px;
  }
  .protect-btn {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .phone-image {
    width: 80%;
    margin-top: 10px;
  }
  .bottom-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-card-section {
    width: 90%;
    bottom: 130px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }
  .green-tick-icon {
    width: 20%;
    height: auto;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .responsive-height {
    height: 550px;
  }
}
