/* Heading */

.back-arrow-title-txt {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  letter-spacing: 0.02em;
  text-align: left;
}

.form-label-txt {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;
}

/* Radio Card */
.protect-plus-txt {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: left;
}
.coverage-txt {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #555555;
}
.plan-amount-txt {
  font-family: Manrope;
  font-size: 22px;
  font-weight: 700;
  line-height: 32.78px;
  text-align: right;
  /* margin-top: -50px; */
}

.select-plan-heading-txt {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #231e1f;
}

/* Upload Container */
.personal-details-heading-txt {
  font-family: Manrope;
  /* font-family: sans-serif; */
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 30px;
}
.upload-mobile-image-heading-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 35px;
}

.upload-your-file-heading {
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  text-align: left;
}
.browse-file-btn-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
  color: #231e1f;
  margin-left: -30px;
  margin-top: -20px;
}

/* Agree */
.agree-label-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.term-condition-txt {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
  color: #333333;
}
.term-condition-txt-two {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  text-align: left;
  color: rgba(51, 51, 51, 1);
}

/* Buttons */
.cancel-btn-container {
  width: Fill (235.5px) px;
  height: Fixed (42px) px;
  gap: 8px;
  border-radius: 4px;
  opacity: 0px;
}

.proceed-to-pay-btn {
  background-color: #000;
  font-family: Manrope;
  font-weight: 700;
  /* font-size: 14px; */
}
.plan-name-value-container {
  /* margin-top: 6px; */
  /* padding: 4px; */
}
/* YourComponent.css */
.fixed-buttons-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: white; /* Optional: to give a background to the buttons */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional: to give a shadow effect */
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px; /* Adjust gap between buttons */
  z-index: 1000; /* Ensure buttons are above other content */
}
.proceed-button {
  /* Optional: Add custom button styles */
}
.label-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align items horizontally */
}
.label1 {
  /* margin-bottom: 10px;  */
  font-size: 1rem;
  font-weight: 700;
  font-family: Manrope;
}
.label2 .proceed-button {
  font-size: 1rem;
  font-weight: 700;
  font-family: Manrope;
  margin-top: -5px;
  color: rgba(10, 105, 191, 1);
}
.label3 .pay-btn {
  font-size: 1.1rem;
  font-weight: 700;
  font-family: Manrope;
  color: #fff;
}
.upload-document-label-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-align: left;
  color: rgba(35, 30, 31, 1);
}
.tap-to-upload-link-txt {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: rgba(85, 85, 85, 1);
  margin-top: -15px;
}

@media (max-width: 576px) {
  .select-plan-heading-txt {
    font-size: 15px;
    margin-top: 10px;
  }
  .coverage-txt {
    font-size: 16px;
  }
  .agree-label-txt {
    font-size: 19px;
    text-align: left;
  }
  .term-condition-txt {
    font-size: 15px;
  }
  .term-condition-txt-two {
    font-size: 15px;
    /* font-weight: 00;
  line-height: 19.12px;
  text-align: left;
  color: #333333; */
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}

/* CustomButton.css */
/* .custom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 100px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2); 
  padding: 0 20px;
  border-radius: 5px;
  position: relative;
}

.custom-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.button-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.proceed-button {
  height: 10%;
  width: 100%;
  margin-left: auto;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 15px;
} */
