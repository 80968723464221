.protection-for-all-heading-txt {
  color: #000;
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 50px;
}
.protection-for-all-card {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  /* border: 1px solid #ececec; */
}
.brand-icon {
  width: 100%;
  height: auto;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
  .protection-for-all-heading-txt {
    font-size: 25px;
    font-weight: 700;
    margin-top: 30px;
  }
  .protection-for-all-card {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
  }
  .brand-icon {
    width: 100%;
    height: auto;
  }
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
  .protection-for-all-heading-txt {
    font-size: 25px;
    font-weight: 700;
    margin-top: 30px;
  }
  .protection-for-all-card {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
  }
  .brand-icon {
    width: 80%;
    height: auto;
  }
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .protection-for-all-heading-txt {
    font-size: 25px;
    font-weight: 700;
    margin-top: 30px;
  }
  .protection-for-all-card {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
  }
  .brand-icon {
    width: 90%;
    height: auto;
  }
}
