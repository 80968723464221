.why-choose-us-heading-txt {
  color: #000;
  font-family: Manrope;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: center;
}
.why-choose-us-card {
  width: 85%;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #eff8ff 0%, #e8f0ff 100%);
  border: 1px solid #ececec;
}
.choose-us-icon {
  max-width: 100%;
  height: auto;
}
.choose-us-icon-two {
  width: 18%;
  height: auto;
}
.label-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #000;
}
.label-txt-two {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #000;
  margin-top: -20px;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */
@media (min-width: 1200px) {
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
  .why-choose-us-card {
    width: 85%;
    padding: 20px;
    border-radius: 8px;
  }
  .why-choose-us-heading-txt {
    font-size: 27px;
    font-weight: 700;
  }
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
  .why-choose-us-card {
    width: 85%;
    padding: 20px;
    border-radius: 8px;
  }
  .why-choose-us-heading-txt {
    font-size: 27px;
    font-weight: 700;
  }
  .label-txt {
    font-size: 11px;
    font-weight: 600;
  }
  .label-txt-two {
    font-size: 11px;
    font-weight: 600;
    margin-top: -20px;
  }
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .why-choose-us-card {
    width: 85%;
    padding: 20px;
    border-radius: 8px;
  }
  .why-choose-us-heading-txt {
    font-size: 25px;
    font-weight: 700;
  }
  .label-txt {
    font-size: 10px;
    font-weight: 600;
  }
  .label-txt-two {
    font-size: 15px;
    font-weight: 600;
    margin-top: -20px;
  }
}
