/* Background gradient */
.footer-logo-section {
  display: flex;
  padding: 10px 0;
  /* justify-content: flex-start; */
  justify-content: space-between;
}

/* Vertical Line */
.footer-vertical-line {
  height: 15;
  margin: auto;
  border-left: 1.5px solid #ececec;
}
.about-label-txt {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.02em;
  /* text-align: left; */
  text-align: right;
  cursor: pointer;
}
.footer-logo-img {
  width: 20%;
}

/* •••••••••• For Extra Large Screens (desktops) •••••••••• */

@media (min-width: 1200px) {
  .footer-container-logo {
    /* width: 20%; */
  }
  .footer-container-label {
    background-color: red;
    /* width: 20%; */
  }
}

/* •••••••••• For Large Screens (desktops) •••••••••• */
@media (min-width: 993px) and (max-width: 1199px) {
}

/* •••••••••• For Medium Screens (tablets) •••••••••• */
@media (min-width: 768px) and (max-width: 992px) {
}

/* •••••••••• For Small Screens (mobiles) •••••••••• */
@media (max-width: 767px) {
  .about-label-txt {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
  }
  .footer-logo-img-dmi {
    width: 80%;
  }
  .footer-logo-img {
    width: 30%;
  }
}

/* •••••••••• For Extra Small Screens (mobiles smaller than 576px) •••••••••• */
@media (max-width: 576px) {
  .footer-logo-section {
    /* display: flex; */
    /* padding: 10px 0; */
    /* justify-content: space-between; */
  }
  .about-label-txt {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .footer-logo-img-dmi {
    width: 80%;
  }
  .footer-logo-img {
    width: 40%;
  }
}
